<template>
	<div>
		<div class="container">
			<div class="handle-box">
				<el-select size="small" v-model="query.type" filterable placeholder="请选择类型" class="handle-input mr10">
					<el-option v-for="item in typeList" :key="item.label" :label="item.label"
						:value="item.value"></el-option>
				</el-select>
				<el-input size="small" v-model="query.cardId" clearable placeholder="请输入id"
					class="handle-input mr10 mt10" style="width: 180px;"></el-input>
				<el-button size="small" type="primary" :class="[theme]" icon="el-icon-search" @click="getData"
					style="margin-top: 10px;">搜索</el-button>
				<el-button size="small" type="primary" style="margin-top: 10px;" :class="[theme]"
					@click="addSetMenu">添加</el-button>
			</div>
			<div class="city-box">
				<div v-for="item in cityList" :key="item.label"
					style="cursor: pointer;display: flex;flex-direction: row;justify-content: space-between;margin-bottom: 6px;"
					@click="tabCity(item)">
					<span>{{ item.label }}</span>
					<span>
						{{ item.value }}
						<i :class="[theme3]" class="el-icon-shopping-cart-full" style="margin-top: 3px;"></i>
					</span>
				</div>
			</div>
			<div style="width: 100%;height: 20px;clear: both;"></div>
			<el-row :gutter="20" type="flex" class="row-bg">
				<el-col :span="24">
					<el-table :data="tableData" border class="table" ref="multipleTable"
						header-cell-class-name="table-header" :row-style="{ height: '20px' }"
						:cell-style="{ padding: '6px' }" :header-cell-style="{ 'text-align': 'center' }"
						@selection-change="handleSelectionChange" :row-class-name="tableRowClassName"
						@row-click="showExpressDialog">
						<el-table-column type="selection" width="55" align="center"></el-table-column>
						<el-table-column prop="card" label="卡类型" align="center"></el-table-column>
						<el-table-column prop="name" label="名称" align="center"></el-table-column>
						<el-table-column prop="name" label="套餐" align="center">
							<template #default="scope">
								<el-tag v-for="item in scope.row.setMealList" :key="item"
									style="display: flex;flex-direction: column;margin:6px 0;">{{item.min}}分钟{{item.price}}元</el-tag>
							</template>
						</el-table-column>
						<el-table-column prop="type" label="类型" align="center">
							<template #default="scope">
								<span v-if="scope.row.type==1">固定套餐</span>
								<span v-if="scope.row.type==2">自选套餐</span>
							</template>
						</el-table-column>
						<el-table-column label="操作" width="190" align="center">
							<template #default="scope">
								<el-button type="primary" :class="[theme]" size="mini"
									@click="handleSocketSend(scope.row)">
									编辑
								</el-button>
								<el-button size="small" type="text" icon="el-icon-delete" class="red"
									@click="handleDelete(scope.row.id)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-col>
			</el-row>
			<div class="pagination">
				<el-pagination background layout="total, sizes, prev, pager, next, jumper"
					:current-page="query.pageIndex" :page-sizes="[30, 100, 200, 500]" :page-size="query.pageSize"
					:total="pageTotal" @size-change="handleSizeChange"
					@current-change="handlePageChange"></el-pagination>
			</div>
		</div>
		<el-dialog title="添加套餐" v-model="addVisible" width="700px" :close-on-click-modal="false">
			<el-form :model="addForm" ref="addForm" label-width="120px">
				<el-form-item label="类型">
					<el-radio-group v-model="addForm.type">
						<el-radio label="1">固定套餐</el-radio>
						<el-radio label="2">自选套餐</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="名称">
					<el-input size="small" type="text" placeholder="请输入套餐名称" v-model="addForm.name"></el-input>
				</el-form-item>
				<el-form-item label="品牌">
					<el-select size="small" v-model="addForm.cardId" filterable placeholder="请选择品牌"
						style="width: 100%;">
						<el-option v-for="item in cardTypeList" :key="item.label" :label="item.value"
							:value="item.label"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item v-for="(item, index) in addForm.menuList" label="套餐" :key="item.key"
					:prop="'menuList.' + index + '.value'">
					<el-input size="small" v-model="item.min" style="width: 125px;margin-right: 10px;"
						placeholder="请输入套餐时长"></el-input>
					<el-input size="small" v-model="item.price" style="width: 125px;margin-right: 10px;"
						placeholder="请输入套餐价格"></el-input>
					<el-button size="small" type="danger" @click.prevent="removeDomain(item)">删除</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click.prevent="addDomain">添加</el-button>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeVisible">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="getSetMealAdd">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<el-dialog title="编辑套餐" v-model="editVisible" width="700px" :close-on-click-modal="false">
			<el-form :model="editForm" ref="editForm" label-width="120px">
				<el-form-item label="类型">
					<el-radio-group v-model="editForm.type">
						<el-radio label="1">固定套餐</el-radio>
						<el-radio label="2">自选套餐</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="名称">
					<el-input size="small" type="text" placeholder="请输入套餐名称" v-model="editForm.name"></el-input>
				</el-form-item>
				<el-form-item v-for="(item, index) in editForm.menuList" label="套餐" :key="item.key"
					:prop="'menuList.' + index + '.value'">
					<el-input size="small" v-model="item.min" style="width: 125px;margin-right: 10px;"
						placeholder="请输入套餐时长"></el-input>
					<el-input size="small" v-model="item.price" style="width: 125px;margin-right: 10px;"
						placeholder="请输入套餐价格"></el-input>
					<el-button size="small" type="danger" @click.prevent="removeDomain2(item)">删除</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click.prevent="addDomain2">添加</el-button>
				</el-form-item>
				<el-form-item style="display: flex;flex-direction: row;justify-content: end;">
					<el-button size="small" @click="closeVisible">取消</el-button>
					<el-button size="small" type="primary" :class="[theme]" @click="getSetMealUpdate">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
	import { setMealAdd, setMealList, setMealUpdate, setMealDelete } from '../api/SjtxCard.js';
	import { getCardType } from '../api/cardType.js';
	export default {
		name: 'inventorySetMenu',
		data() {
			return {
				theme: localStorage.getItem('theme'),
				query: {
					type: '',
					cardId: '',
				},
				tableData: [],
				multipleSelection: [],

				uploadData: {
					userId: localStorage.getItem('user')
				},
				path: process.env.BASE_URL,
				typeList: [{
					value: '1',
					label: '固定套餐'
				}, {
					value: '2',
					label: '自选套餐'
				}],
				addForm: {
					type: '1',
					name: '',
					cardId: '',
					content: [{
						price: '',
						min: '',
					}],
					menuList: [{
						price: '',
						min: '',
					}],
				},
				addVisible: false,
				editForm: {
					type: '1',
					name: '',
					content: [{
						price: '',
						min: '',
					}],
					menuList: [{
						price: '',
						min: '',
					}],
				},
				editVisible: false,
				cardTypeList: []
			};
		},
		created() {
			this.getData();
		},
		methods: {

			tableRowClassName({ rowIndex }) {
				if ((rowIndex + 1) % 2 === 0) {
					return 'oddRow';
				}
				return 'evenRow';
			},
			getData() {
				let data = {
					type: this.query.type,
					cardId: this.query.cardId,
				};
				setMealList(data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			//添加
			addSetMenu() {
				this.addVisible = true;
				this.addForm = {
					type: '1',
					name: '',
					cardId: '',
					content: [{
						price: '',
						min: '',
					}],
					menuList: [{
						price: '',
						min: '',
					}],
				};
				getCardType('').then(res => {
					this.cardTypeList = res.data;
				});
			},
			//关闭出库弹窗
			closeVisible() {
				this.addVisible = false;
				this.editVisible = false;
			},
			//添加套餐时 添加套餐行
			addDomain() {
				this.addForm.menuList.push({
					min: '',
					price: '',
				});
				this.addForm.content.push({
					min: '',
					price: '',
				});
			},
			//添加套餐时 删除套餐行
			removeDomain(item) {
				if (this.addForm.menuList.length > 1) {
					var index = this.addForm.menuList.indexOf(item)
					if (index !== -1) {
						this.addForm.menuList.splice(index, 1)
						this.addForm.content.splice(index, 1)
					}
				}
			},
			//编辑套餐时 添加套餐行
			addDomain2() {
				this.editForm.menuList.push({
					min: '',
					price: '',
				});
				this.editForm.content.push({
					min: '',
					price: '',
				});
			},
			//编辑套餐时 删除套餐行
			removeDomain2(item) {
				if (this.editForm.menuList.length > 1) {
					var index = this.editForm.menuList.indexOf(item)
					if (index !== -1) {
						this.editForm.menuList.splice(index, 1)
						this.editForm.content.splice(index, 1)
					}
				}
			},
			getSetMealAdd() {
				let data = {
					cardId: this.addForm.cardId,
					name: this.addForm.name,
					content: JSON.stringify(this.addForm.menuList),
					type: this.addForm.type,
				};
				setMealAdd(data).then(res => {
					if (res.code == 200) {
						this.$message.success(res.message);
						this.getData();
						this.addVisible = false;
					} else {
						this.$message.error(res.message);
					}
				});
			},
			// 多选操作
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},

			// 分页导航
			handlePageChange(val) {
				this.query.pageIndex = val;
				this.getData();
			},
			//下一页
			handleSizeChange(val) {
				this.query.pageSize = val;
				this.getData();
			},
			handleSocketSend(row) {
				this.editVisible = true;
				this.editForm.type = String(row.type);
				this.editForm.id = row.id;
				this.editForm.name = row.name;
				this.editForm.menuList = row.setMealList;
			},
			getSetMealUpdate() {
				let param = {
					id: this.editForm.id,
					type: this.editForm.type,
					name: this.editForm.name,
					content: JSON.stringify(this.editForm.menuList),
				};
				setMealUpdate(param).then(res => {
					if (res.code == 200) {
						this.editVisible = false;
						this.$message.success('编辑成功');
						this.getData();
					} else {
						this.$message.error(res.message);
					}
				});
			},
			handleDelete(id) {
				this.$confirm('确定要删除吗？', '提示', {
						type: 'warning'
					})
					.then(() => {
						let param = {
							id: id,
						};
						setMealDelete(param).then(res => {
							if (res.code == 200) {
								this.$message.success('删除成功');
								this.getData();
							} else {
								this.$message.error(res.message);
							}
						});
					})
					.catch(() => {});
			}
		}
	};
</script>

<style scoped>
	.handle-box {
		margin-bottom: 50px;
	}

	/* a:hover{background: #66b1ff} */
	.handle-select {
		width: 240px;
	}

	.table {
		width: 100%;
		font-size: 12px;
	}

	.mr10 {
		margin-right: 10px;
	}

	.mt10 {
		margin-top: 10px;
	}

	.red {
		color: red;
	}
</style>